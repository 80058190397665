blockquote {
	font-family: $sans-font-family;
	font-size: 24px;
	line-height: 36px;
	font-weight: 400;
	font-style: italic;
	i,
	em {
		font-style: normal;
	}
	color: #151515;
	border-top: 3px solid #151515;
	padding: 30px 0;
	margin: 30px 0 0 0;
	cite {
		display: block;
		font-family: $sans-font-family;
		font-size: 18px;
		line-height: 32px;
		padding: 0 0 0 42px;
		margin: 30px 0 0 0;
		color: #151515;
		font-style: normal;
		//background: transparent url('../images/quote.svg') no-repeat left center;
	}
}
