/**
 * Toolkit styles
 */

@import 'config';

@import 'elements/blockquote';
@import 'components/platform_header';

body {
	font-family: $sans-font-family;
}

